<template>
  <div>
    <!-- Filters -->
    <UsersListFilters
      :sort-order.sync="sortOrderValue"
      :sort-field.sync="sortFieldValue"
      :verify-filter.sync="verifyFilter"
      :order-options="orderOptions"
      :field-options="fieldOptions"
      :verify-options="verifyOptions"
    />
    <!-- <download-csv
      class="ml-2"
      :data="exportData"
      name="download.csv"
    >

      <b-button
        class="btn-change-status"
        @click="() => {}"
      >
        Export CSV
      </b-button>
    </download-csv> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>users</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        :items="fetchUsers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive
        show-empty
        primary-key="id"
      >
        <template #empty>
          <div
            v-if="list.loading"
            class="text-center text-dark my-2"
          >
            <p>Loading... Please wait</p>
          </div>

          <div
            v-else-if="!list.loading && fetchUsers.length === 0"
            class="text-center text-dark my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- Column: ID -->
        <template #cell(userId)="{ item }">
          <div class="d-flex align-items-center font-weight-bold id-column">
            <b-link :to="{ name: 'users-view', params: { id: item.userId } }">
              {{ item.userId }}
            </b-link>
          </div>
        </template>

        <!-- Column: User -->
        <template #cell(userName)="{ item }">
          <b-media vertical-align="center font-weight-bold d-block text-nowrap text-body">
            {{ item.userName }}
          </b-media>
        </template>

        <!-- Column: firstName -->
        <template #cell(firstName)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-extrabold">
              {{ item.firstName }}
            </div>
          </b-media>
        </template>

        <!-- Column: surName -->
        <template #cell(surName)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.lastName }}
            </div>
          </b-media>
        </template>
        <!-- Column: balance -->
        <template #cell(balance)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatCurrencyBuiltInFunc(item.balance) }}
            </div>
          </b-media>
        </template>
        <!-- Column: dateJoined -->
        <template #cell(createdTime)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatDateTimeTracking(item.createdTime) }}
            </div>
          </b-media>
        </template>

        <!-- Column: mobile -->
        <template #cell(mobile)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.phoneNumber }}
            </div>
          </b-media>
        </template>

        <!-- Column: wallet -->
        <!-- <template #cell(wallet)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.depositLimitValue }}
            </div>
          </b-media>
        </template> -->

        <!-- Column: activeEntry -->
        <!-- <template #cell(activeEntry)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.isActive ?'Active' : 'UnActive' }}
            </div>
          </b-media>
        </template> -->

        <!-- Column: verified -->
        <template #cell(verified)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.isVerified ? 'Verified' : 'Not Verified' }}
            </div>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.isBanned ? 'Disabled' : 'Enabled' }}
              {{ item.selfExcluded ? '/Closed' : '' }}
              {{ item.isActive ? '' : '/Deleted' }}
              {{ checkTimeout(item.breakTime) ? '/Timeout' : '' }}
              {{ item.isBlocked ? '/Blocked' : '' }}
            </div>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="{ item }">
          <b-dropdown
            variant="link"
            no-caret
            boundary="viewport"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'users-view', params: { id: item.userId } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showModal(UserAction.BLOCK, item.userId, item.isBanned)">
              <feather-icon
                v-if="item.isBanned"
                icon="UnlockIcon"
              />
              <feather-icon
                v-else
                icon="LockIcon"
              />

              <span class="align-middle ml-50">{{ item.isBanned ? 'Enable' : 'Disable' }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showModal(UserAction.VERIFY, item.userId, item.isVerified)">
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">
                {{ !item.isVerified ? 'Verify User' : 'Unverify User' }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="item.breakTime || item.selfExcluded"
              @click="showModal(UserAction.REOPEN, item.userId, item.isBanned)"
            >
              <feather-icon icon="UnlockIcon" />
              <span class="align-middle ml-50">Re-open</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="item.isBlocked"
              @click="showModal(UserAction.UNBLOCK, item.userId, item.isBlocked)"
            >
              <feather-icon icon="UnlockIcon" />
              <span class="align-middle ml-50"> Unblock </span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="isRoleSuperAdmin() && item.closeAccountType === UserClosed.closedTerminal"
              @click="showModal('reopen', item.userId)"
            >
              <feather-icon icon="UnlockIcon" />
              <span class="align-middle ml-50"> Re-open Account</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              v-if="item.isActive"
              @click="showModal('delete', item.userId)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} users
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      ref="modal"
      :title="modal.title"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="isSubmitting"
      @ok="handleSubmitModal"
    >
      <b-card-text class="text-title">
        {{ modal.content }}
      </b-card-text>
      <template #modal-ok>
        <Spinner v-if="isSubmitting" />
        <span v-else>Submit</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { isRoleSuperAdmin } from '@/helpers/permission'
import useToastHandler from '@/services/toastHandler'
import Spinner from '@core/components/Spinner'
// import VerifyIcon from '@core/components/VerifyIcon'
import UserEnum, { UserAction, UserClosed } from '@core/utils/enum/user'
import { avatarText } from '@core/utils/filter'
import {
  // BAvatar,
  // BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
// import ExportCSV from './ExportCSV.vue'
import {
  formatDateTimeAustralia,
  formatDate,
  formatCurrencyBuiltInFunc,
  formatDateTimeLocal,
  formatDateTimeTracking,
} from '@/helpers/utils'
import { formatDistanceStrict } from 'date-fns'
import UsersListFilters from './UsersListFilters'
import useUsersList from './useUsersList'

export default {
  components: {
    UsersListFilters,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    // BAvatar,
    BLink,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    // VerifyIcon,
    Spinner,
    // ExportCSV,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      UserClosed,
      action: {
        type: '',
        id: '',
        status: '',
      },
      modal: {
        title: '',
        content: '',
      },
      UserAction,
      formatDateTimeAustralia,
      // exportData: [],
    }
  },
  computed: {
    ...mapState({
      verifyAccount: s => s.user.verifyAccount,
      list: s => s.user.list,
      verify: s => s.user.verify,
      block: s => s.user.block,
      delete: s => s.user.remove,
      reopen: s => s.user.reopenAccount,
    }),
    ...mapState('user/detail', ['loadingClientProfile']),
    ...mapState('user/verify', ['loading']),

    isLoading() {
      return this[this.action.type].loading
    },

    isSubmitting() {
      return this.loading || this.loadingClientProfile
    },
    checkTimeout() {
      return breakTime => {
        if (breakTime) {
          const time = new Date(breakTime).getTime() - new Date().getTime()
          if (time > 0) {
            return `${formatDistanceStrict(new Date(breakTime), new Date())}`
          }
          return null
        }
        return null
      }
    },

  },
  // async mounted() {
  //   const res = await this.asyncUsers({
  //     limit: 100,
  //     page: 3,
  //   })
  //   this.exportData = res
  // },
  methods: {
    ...mapActions({
      asyncUsers: 'user/list/asyncUsers',
      asyncBlockUser: 'user/block/blockUser',
      asyncVerifyUser: 'user/verify/verifyUser',
      asyncReopenUser: 'user/reopenAccount/reopenUser',
      asyncDeleteUser: 'user/remove/deleteUser',
      asyncVerifyAccountUser: 'user/verifyAccount/verifyAccountUser',
      updateClientProfile: 'user/detail/updateClientProfile',
    }),

    async fetchUsers() {
      const data = await this.asyncUsers({
        search: this.searchQuery,
        limit: this.perPage,
        page: this.currentPage,
        isVerified: this.verifyFilter,
        sortValue: this.sortOrderValue,
        sortField: this.sortFieldValue,
      })

      if (!data && this.list.error) return this.showToastError(false, 'Error fetching users list')

      this.totalUsers = this.list.totalPage
      return data
    },

    setModal(title, content) {
      this.modal.title = title
      this.modal.content = content
    },

    showModal(type, id, status) {
      this.action = { id, type, status }
      if (type === UserAction.VERIFY) {
        this.setModal('Verify User', `Are you sure you want to ${status ? 'unverify' : 'verify'} this user?`)
      }
      if (type === UserAction.BLOCK) {
        this.setModal('Block User', `Are you sure you want to ${status ? 'enable' : 'disable'} this user?`)
      }
      if (type === UserAction.UNBLOCK) {
        this.setModal('UnBlock User', 'Are you sure you want to unblock this user?')
      }
      if (type === UserAction.DELETE) this.setModal('Delete User', 'Are you sure you want to delete this user?')
      if (type === UserAction.REOPEN) this.setModal('Reopen User', 'Are you sure you want to reopen this user?')
      if (type === UserAction.VERIFY_ACCOUNT) {
        this.setModal('Verify User', 'Are you sure you want to verify this user?')
      }

      this.$refs.modal.show()
    },

    async handleSubmitModal(event) {
      event.preventDefault()
      const { id, type, status } = this.action

      if (type === UserAction.VERIFY) await this.verifyUser(id, status)
      if (type === UserAction.BLOCK) await this.blockUser(id, status)
      if (type === UserAction.UNBLOCK) await this.unblockUser(id, status)

      if (type === UserAction.REOPEN) await this.reopenAccount(id)
      if (type === UserAction.DELETE) await this.deleteUser(id)
      if (type === UserAction.VERIFY_ACCOUNT) await this.verifyAccountUser(id)
      if (type === UserAction.VERIFY_ACCOUNT) await this.verifyAccountUser(id)

      if (!this.isSubmitting) {
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }
    },

    async verifyUser(id, status) {
      const result = await this.asyncVerifyUser({ id, isVerified: !status ? 1 : 0 })
      const statusText = !status ? 'verified' : 'unverified'

      if (!result) {
        return
      }
      this.showToastSuccess('Success', `User ${statusText} successfully!`)
      this.refUserListTable.refresh()
    },

    async blockUser(id, status) {
      const result = await this.updateClientProfile({ userId: id, isBanned: !status ? 1 : 0 })
      const statusText = status ? 'enabled' : 'disabled'

      if (!result) return
      this.showToastSuccess('Success', `User ${statusText} successfully!`)
      this.refUserListTable.refresh()
    },
    async unblockUser(id) {
      const result = await this.updateClientProfile({ userId: id, isBlocked: 0 })

      if (!result) return
      this.showToastSuccess('Success', 'Unblock user successfully!')
      this.refUserListTable.refresh()
    },

    async reopenAccount(id) {
      const result = await this.updateClientProfile({
        userId: id,
        breakTime: null,
        selfExcluded: 0,
        breakStartDate: null,
        closedDate: null,
      })
      if (!result) return
      this.showToastSuccess('Success', 'Re-open user successfully!')
      this.refUserListTable.refresh()
    },

    async deleteUser(id) {
      const result = await this.updateClientProfile({ userId: id, isActive: 0 })

      if (!result) return
      this.showToastSuccess('Success', 'User deleted successfully!')
      this.refUserListTable.refresh()
    },
    async verifyAccountUser(userId) {
      const result = await this.asyncVerifyAccountUser({ userId })
      if (!result) return
      this.showToastSuccess('Success', 'User verify successfully!')
      this.refUserListTable.refresh()
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()
    const roleId = UserEnum.UserRoleEnum.SuperAdmin
    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      searchQuery,
      refUserListTable,
      sortBy,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusName,

      orderOptions,
      verifyOptions,
      fieldOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
      verifyFilter,
      sortFieldValue,
      sortOrderValue,
    } = useUsersList()

    return {
      getUserData,
      roleId,
      // Sidebar
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      refUserListTable,
      isSortDirDesc,
      sortBy,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusName,

      orderOptions,
      verifyOptions,
      fieldOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
      verifyFilter,
      sortFieldValue,
      sortOrderValue,

      showToastError,
      showToastSuccess,

      isRoleSuperAdmin,
      formatDate,
      formatCurrencyBuiltInFunc,
      formatDateTimeLocal,
      formatDateTimeTracking,
      formatDistanceStrict,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.id-column {
  max-width: 300px;
  min-width: 120px;
  word-break: break-word;
}
.text-title {
  color: black;
  font-weight: 500;
}
</style>
