import { computed, ref, watch } from '@vue/composition-api'
// Notification
import { debounce } from 'debounce'

export default function useUsersList() {
  // Use toast
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'userId', label: 'id number' },
    { key: 'username' },
    { key: 'firstName' },
    { key: 'lastName', label: 'Sur Name' },
    { key: 'createdTime', label: 'date joined' },
    { key: 'mobile' },
    { key: 'balance' },
    { key: 'verified' },
    { key: 'status' },
    { key: 'actions' },
  ]

  const perPage = ref(100)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const roleFilter = ref(null)
  const sortOrderValue = ref('DESC')
  const sortFieldValue = ref('createdTime')

  const statusFilter = ref(null)
  const verifyFilter = ref(null)
  const isSortDirDesc = ref(false)
  const sortBy = ref(null)

  const orderOptions = [
    { label: 'ASC', value: 'ASC' },
    { label: 'DESC', value: 'DESC' },
  ]

  const statusOptions = [
    { label: 'Active', value: 0 },
    { label: 'Inactive', value: 1 },
  ]

  const verifyOptions = [
    { label: 'Verified', value: 1 },
    { label: 'Not verified', value: 0 },
  ]
  const fieldOptions = [
    { label: 'Date Join', value: 'createdTime' },
    { label: 'Update Time', value: 'updatedTime' },
    { label: 'Username', value: 'username' },
    { label: 'Email', value: 'email' },
  ]

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, roleFilter, statusFilter, verifyFilter, sortFieldValue, sortOrderValue], () => {
    refetchData()
  })

  watch(
    [searchQuery],
    debounce(() => {
      totalUsers.value = 0
      refetchData()
      // eslint-disable-next-line comma-dangle
    }, 700)
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'admin') return 'success'
    if (role === 'superadmin') return 'primary'
    if (role === 'upcsupport') return 'warning'
    return 'info'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'user') return 'UserIcon'
    if (role === 'admin') return 'ServerIcon'
    if (role === 'superadmin') return 'SettingsIcon'
    if (role === 'upcsupport') return 'LinkIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => (status ? 'secondary' : 'success')

  const resolveUserStatusName = status => (status ? 'inactive' : 'active')

  return {
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    refUserListTable,
    isSortDirDesc,
    sortBy,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusName,

    orderOptions,
    statusOptions,
    verifyOptions,
    fieldOptions,
    // Extra Filters
    roleFilter,
    statusFilter,
    verifyFilter,
    sortOrderValue,
    sortFieldValue,
  }
}
