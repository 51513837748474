<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Role</label>
          <v-select
            :value="roleFilter"
            :options="orderOptions"
            :reduce="val => val.value"
            @input="val => $emit('update:roleFilter', val)"
          />
        </b-col> -->

        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :value="verifyFilter"
            :options="verifyOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="val => $emit('update:verifyFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Sort Order</label>
          <v-select
            :value="sortOrder"
            :options="orderOptions"
            :reduce="val => val.value"
            @input="val => $emit('update:sortOrder', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Sort Field</label>
          <v-select
            :value="sortField"
            :options="fieldOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="val => $emit('update:sortField', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"

          class="d-flex align-items-end justify-content-end"
        >
          <b-link
            :to="{ name: 'users-create-account' }"
          >
            <b-button
              size="sm"
              variant="primary mr-1"
            >
              <span class="ml-25 align-middle">Create account</span>
            </b-button>
          </b-link>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { numberProp, arrayProp, stringProp } from '@/helpers/props'
import { isRoleSuperAdmin } from '@/helpers/permission'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BLink,
  },
  props: {
    statusFilter: numberProp(),
    sortField: stringProp(),
    sortOrder: stringProp(),
    verifyFilter: numberProp(),
    orderOptions: arrayProp(),
    fieldOptions: arrayProp(),
    verifyOptions: arrayProp(),
  },
  setup() {
    return {
      isRoleSuperAdmin,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
